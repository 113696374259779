.Dashboards {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	white-space: nowrap;
}

.topBar {
	width: 100%;
	height: min-content;
	background-color: rgb(30, 30, 55);
	display: flex;
	overflow-x: auto;
}

.tab {
	width: 25%;
	height: min-content;
	background-color: rgb(73, 73, 125);
	margin-top: 8px;
	margin-right: 8px;
	text-align: center;
	padding: 8px;
	border-radius: 4px 4px 0 0;
	transition: 0.2s;
	user-select: none;
}

.tabSelected {
	width: 25%;
	height: min-content;
	background-color: rgb(124, 124, 180);
	margin-top: 8px;
	margin-right: 8px;
	text-align: center;
	padding: 8px;
	border-radius: 4px 4px 0 0;
	transition: 0.2s;
	user-select: none;
	box-shadow: 4px -4px 4px black;
}

.tab:hover {
	scale: 1.02 1;
	cursor: pointer;
	box-shadow: 4px -4px 4px black;
}

.container {
	width: 100%;
	height: calc(100vh - 52px);
	overflow: auto;
}

.dashboard {
	width: 100%;
	height: 100%;
	border: none;
	display: block;
}

.dashboard2 {
	width: 60%;
	height: 90%;
	margin: 8px auto;
	border: none;
	border-radius: 4px;
	display: block;
}

.dashboardHidden {
	display: none;
	visibility: hidden;
}

.lastUpdated {
	margin: 8px auto;
	width: 250px;
	text-align: center;
	color: white;
	background-color: rgb(73, 73, 125);
	padding: 10px;
	border-radius: 8px;
}
