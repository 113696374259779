.ThumbsUp {
	height: 25px;
	width: fit-content;
	display: flex;
	border-radius: 8px;
	user-select: none;
	background-color: rgb(49, 42, 138);
	padding: 4px 8px;
	transition: 0.5s;
	margin-right: 8px;
	margin-top: 8px;
}

.ThumbsUpLiked {
	height: 25px;
	width: fit-content;
	display: flex;
	border-radius: 8px;
	user-select: none;
	background-color: rgb(31, 201, 102);
	padding: 4px 8px;
	transition: 0.5s;
	margin-right: 8px;
	margin-top: 8px;
}

.number {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 12px;
}

.image {
	width: 25px;
	height: 25px;
	transition: 0.1s;
	cursor: pointer;
}

.image:hover {
	scale: 1.2;
}

.loggedOutImage {
	width: 25px;
	height: 25px;
	transition: 0.1s;
	-webkit-user-drag: none;
}
