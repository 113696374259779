.Home {
	width: 50%;
	height: calc(100vh - 32px);
	border-radius: 8px;
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	margin-top: 8px;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.HomeMobile {
	width: 100%;
	height: 100vh;
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.text {
	text-align: center;
}

.title {
	font-size: 40px;
	text-align: center;
}

.link,
.link:link,
.link:visited {
	color: lightblue;
	cursor: pointer;
}

.buttons {
	display: flex;
}

.button {
	width: fit-content;
	height: fit-content;
	padding: 8px;
	text-align: center;
	background-color: rgb(49, 42, 138);
	border-radius: 8px;
	user-select: none;
	transition: 0.2s;
}

.button:hover {
	cursor: pointer;
	scale: 1.05;
}

.post {
	width: calc(100% - 16px);
	height: fit-content;
	background-color: rgb(57, 75, 112);
	border-radius: 8px;
	margin-top: 16px;
	padding: 8px;
}

.postTitle {
	font-size: 24px;
}

.postMessage {
	font-size: 18px;
	white-space: pre-line;
}

.postCallsign {
	font-size: 14px;
}

.postTime {
	font-size: 14px;
}

.commentText {
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	user-select: none;
	cursor: pointer;
}

.commentText:hover {
	text-decoration: underline;
}

.comment {
	background-color: rgb(57, 75, 112);
	border-radius: 8px;
	padding: 8px;
	margin-top: 4px;
	font-size: 14px;
}

.editMessageInput {
	width: calc(100% - 16px);
	font-size: 14px;
	outline: none;
	border: none;
	resize: none;
	background-color: rgb(49, 42, 138);
	color: white;
	border-radius: 8px;
	text-align: left;
	font-family: Arial;
	margin-top: 8px;
	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
}

.addCommentText {
	font-size: 14px;
	margin-top: 8px;
	margin-left: auto;
	cursor: pointer;
}

.addCommentText:hover {
	text-decoration: underline;
}

.cancelCommentText {
	margin-top: 8px;
	cursor: pointer;
}

.cancelCommentText:hover {
	text-decoration: underline;
}

.callsignContainer {
	display: flex;
}

.callsignInput {
	outline: none;
	font-size: 20px;
	margin-right: 8px;
	margin-left: auto;
	padding-left: 8px;
}

.callsignButton {
	width: fit-content;
	padding: 8px;
	text-align: center;
	background-color: rgb(49, 42, 138);
	border-radius: 8px;
	user-select: none;
	transition: 0.2s;
	margin-right: auto;
}

.callsignButton:hover {
	cursor: pointer;
	scale: 1.05;
}

.createPostTitleInput {
	outline: none;
	width: calc(100% - 16px);
	font-size: 20px;
	padding: 8px;
	margin-top: 8px;
	background-color: rgb(49, 42, 138);
}

.createPostSmallText {
	font-size: 14px;
	margin-top: 8px;
}

.postButton {
	margin-left: auto;
}

.deleteCommentText {
	margin-left: auto;
	cursor: pointer;
	user-select: none;
	font-size: 8px;
}

.deleteCommentText:hover {
	text-decoration: underline;
}

.editPostText {
	height: fit-content;
	cursor: pointer;
	user-select: none;
	font-size: 12px;
	margin-top: auto;
	margin-left: 32px;
}

.editPostText:hover {
	text-decoration: underline;
}

.replyPostText {
	height: fit-content;
	cursor: pointer;
	user-select: none;
	font-size: 12px;
	margin-left: auto;
	margin-top: auto;
}

.replyPostText:hover {
	text-decoration: underline;
}

.bottomForScroll {
	height: 200px;
}

.signInWithEmailButton {
	margin-left: 8px;
}

.reactions {
	display: flex;
	flex-flow: row wrap;
}

.postMessage a {
	color: rgb(0, 195, 255);
	text-decoration: underline;
}
