.Home {
	width: 50%;
	height: calc(100vh - 92px);
	border-radius: 8px;
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	text-align: center;
	margin-top: 8px;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.HomeMobile {
	width: 100%;
	height: calc(100vh - 76px);
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	text-align: center;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.title {
	font-size: 40px;
}

.link,
.link:link,
.link:visited {
	color: lightblue;
	cursor: pointer;
}

.image {
	width: 100%;
	border-radius: 4px;
}

.blink {
	color: rgb(0, 204, 255);
	animation: blinker 3s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 50%;
		scale: 0.98;
	}
}

.table {
	margin: 0 auto;
}

.row1,
.row2 {
	display: flex;
	justify-content: center;
}

.table_titles {
	display: flex;
	justify-content: center;
}

.row1 div {
	width: 100%;
	height: min-content;
	margin-bottom: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: rgba(128, 128, 128, 0.2);
}

.row2 div {
	width: 100%;
	height: min-content;
	margin-bottom: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: rgba(128, 128, 128, 0.5);
}

.table_titles div {
	width: 100%;
	background-color: rgba(128, 128, 128, 0.5);
	padding-top: 4px;
	padding-bottom: 4px;
	margin-bottom: 4px;
}

input {
	background-color: rgba(92, 92, 255, 0.452);
	color: white;
	border: none;
	border-radius: 4px;
}

.latloninputs {
	display: flex;
	justify-content: center;
}

.latloninputs * {
	width: 25%;
}

.button {
	width: fit-content;
	height: min-content;
	background-color: rgba(92, 92, 255, 0.452);
	padding: 8px;
	border-radius: 4px;
}

.button:hover {
	cursor: pointer;
}

.bottomForScroll {
	height: 200px;
}
