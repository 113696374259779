.LeftBar {
	height: calc(100vh - 8px);
	width: min-content;
	background-color: rgb(30, 30, 55);
	padding-top: 8px;
}

.LeftBarTop {
	height: 100vh;
	width: min-content;
	background-color: rgb(30, 30, 55);
	overflow: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.LeftBarTop::-webkit-scrollbar {
	display: none;
}

.itemMobile {
	height: min-content;
	width: calc(100vw - 16px);
	background-color: rgb(73, 73, 125);
	border-radius: 4px;
	margin-bottom: 8px;
	margin-right: 8px;
	margin-left: 8px;
	text-align: center;
	padding: 18px 0px;
	transition: 0.1s;
	user-select: none;
}

.itemMobile:hover {
	cursor: pointer;
	scale: 1.05;
	box-shadow: 4px 4px 4px black;
}

.itemSelectedMobile {
	height: min-content;
	width: calc(100vw - 16px);
	background-color: rgb(124, 124, 180);
	border-radius: 4px;
	margin-bottom: 8px;
	margin-right: 8px;
	margin-left: 8px;
	text-align: center;
	padding: 18px 0px;
	transition: 0.1s;
	user-select: none;
	box-shadow: 4px 4px 4px black;
}

.item {
	height: min-content;
	width: 200px;
	background-color: rgb(73, 73, 125);
	border-radius: 4px;
	margin-bottom: 8px;
	margin-right: 8px;
	margin-left: 8px;
	text-align: center;
	padding: 18px 0px;
	transition: 0.1s;
	user-select: none;
}

.item:hover {
	cursor: pointer;
	scale: 1.05;
	box-shadow: 4px 4px 4px black;
}

.itemSelected {
	height: min-content;
	width: 200px;
	background-color: rgb(124, 124, 180);
	border-radius: 4px;
	margin-bottom: 8px;
	margin-right: 8px;
	margin-left: 8px;
	text-align: center;
	padding: 18px 0px;
	transition: 0.1s;
	user-select: none;
	box-shadow: 4px 4px 4px black;
}

.showNavBarBox {
	width: fit-content;
	height: fit-content;
	background-color: rgb(73, 73, 125);
	border-radius: 8px;
	position: absolute;
	left: 8px;
	top: 8px;
	cursor: pointer;
}

.line {
	width: 24px;
	height: 4px;
	background-color: white;
	border-radius: 2px;
	margin: 4px;
}

.bottomForScroll {
	height: 200px;
}
