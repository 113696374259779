.App {
	width: 100vw;
	height: 100vh;
	font-family: Arial, Helvetica, sans-serif;
	display: flex;
	color: white;

	background-image: url("images/background.jpg");
	background-size: cover;
}

/* .backgroundImage {
	width: calc(100% - 200px);
	height: min-content;
	position: absolute;
	margin-left: 190px;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
} */

.fullscreen {
	width: 100%;
	height: 100%;
}

.seeBackgroundImage {
	user-select: none;
	cursor: pointer;
	transition: 0.1s;
	margin-top: auto;
	margin-bottom: 5px;
	margin-left: 8px;
	font-size: 14px;
	right: 8px;
	bottom: 26px;
	position: absolute;
}

.seeBackgroundImage:hover {
	scale: 1.05;
	text-decoration: underline;
}
