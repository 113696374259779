.photos {
	width: 100%;
	max-width: calc(100vw - 216px);
}

.thumbnails {
	display: flex;
	width: 50%;
	margin: 0 auto;
	height: 116px;
	border-radius: 8px;
	overflow: hidden;
	background-color: rgb(30, 30, 55);
}

.thumbnailsMobile {
	display: flex;
	width: 100vw;
	margin: 0 auto;
	height: 116px;
	overflow: hidden;
	background-color: rgb(30, 30, 55);
}

.thumbnail {
	max-width: 100px;
	max-height: 100px;
	display: flex;
	margin-right: 8px;
	margin-left: 8px;
	margin-top: 8px;
}

.selectedThumbnail {
	max-width: 100px;
	max-height: 100px;
	display: flex;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 5px;
	border: 3px solid rgb(255, 255, 255);
	border-radius: 12px;
}
