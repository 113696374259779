.image {
	width: auto;
	max-width: calc(100% - 16px);
	padding: 8px;
	height: calc(100vh - 200px);
	user-select: none;
	object-fit: contain;
	border-radius: 16px;

	opacity: 1;
	animation: fade 1s ease-out;
}

.imageMobile {
	width: calc(100% - 16px);
	height: calc(100vh - 160px);
	padding: 8px;
	user-select: none;
	object-fit: contain;
	border-radius: 8px;

	opacity: 1;
	animation: fade 1s ease-out;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.box {
	width: fit-content;
	background-color: rgb(30, 30, 55);
	border-radius: 8px;
	padding-bottom: 8px;
	margin: 8px auto;
	color: white;
	text-align: center;
}

.boxMobile {
	width: 100vw;
	background-color: rgb(30, 30, 55);
	padding-bottom: 8px;
	color: white;
	text-align: center;
}

.thumbnail {
	min-width: 100px;
	min-height: 100px;
	border-radius: 8px;
	object-fit: cover;
	user-select: none;
	cursor: pointer;
}
