.Home {
	width: 50%;
	height: calc(100vh - 32px);
	border-radius: 8px;
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	text-align: center;
	margin-top: 8px;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.HomeMobile {
	width: 100%;
	height: 100vh;
	background-color: rgb(30, 30, 55);
	margin: 0 auto;
	padding: 8px;
	text-align: center;
	font-size: 18px;
	overflow: auto;
	box-shadow: 4px 4px 4px black;
}

.title {
	font-size: 40px;
}

.link,
.link:link,
.link:visited {
	color: lightblue;
	cursor: pointer;
}

.image {
	width: 100%;
	border-radius: 4px;
}

.half_image {
	width: 50%;
	border-radius: 4px;
}

.blink {
	color: rgb(0, 204, 255);
	animation: blinker 3s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 50%;
		scale: 0.98;
	}
}

.button {
	width: fit-content;
	height: min-content;
	background-color: rgba(92, 92, 255, 0.452);
	padding: 8px;
	border-radius: 4px;
}

.button:hover {
	cursor: pointer;
}

.editMessageInput {
	width: 100%;
	font-size: 18px;
	outline: none;
	border: none;
	resize: none;
	background-color: rgb(30, 30, 55);
	color: white;
	border-radius: 4px;
	text-align: left;
	font-family: Arial;
	padding-top: 8px;
	overflow: hidden;
}

.editMessageInput:focus {
	background-color: rgb(50, 50, 75);
}

.loginButton {
	position: absolute;
	right: 8px;
	top: 8px;
	opacity: 0%;
}

.loginButton:hover {
	opacity: 100%;
}

.bottomForScroll {
	height: 200px;
}

.countdownTitle {
	font-size: 32px;
}

.countdown {
	display: flex;
	flex-flow: row wrap;
}

.countdownBox {
	width: 100px;
	height: 100px;
	margin-top: 8px;
	margin-left: auto;
	margin-right: auto;
	background-color: rgba(92, 92, 255);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
}

.countdownNumber {
	font-size: 40px;
	margin-top: auto;
}

.countdownText {
	font-size: 16px;
	margin-top: auto;
	margin-bottom: 4px;
}

.netStartedText {
	font-size: 40px;
	color: greenyellow;
}

.link,
.link:link,
.link:visited {
	color: lightblue;
	cursor: pointer;
	text-decoration: underline;
}
